import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";
import CommonParagraph from "../../component/Blog/CommonParagraph";
import Heading from "../../component/Blog/Heading";
import BlogLink from "../../component/Blog/BlogLink";
import { blogList } from "../../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter((blog) => blog.url == "/san-diego-adu-laws");

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <a
                  href={blogData[0].authorUrl && blogData[0].authorUrl}
                  className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]"
                >
                  By {blogData[0].author}
                </a>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../images/The Selby Team_San Diego ADU Laws_ What Homeowners Need to Know.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                Thanks to new opportunities for additional income, a bit more space, or
                property-boosting potential, accessory dwelling units (ADUs) are gaining popularity.
                That's especially true in San Diego, where the real estate market is competitive.
              </CommonParagraph>
              <CommonParagraph>
                Whether you're a buyer, seller, or just exploring your options, understanding
                California ADU laws and regulations can help you make informed decisions.
              </CommonParagraph>
              <CommonParagraph>
                Here, we'll break down what ADUs are, go over just a few of their many benefits, and
                review some key San Diego ADU laws that homeowners should be aware of.
              </CommonParagraph>
              <Heading type="h2">What Are Accessory Dwelling Units?</Heading>
              <CommonParagraph>
                Granny flats, in-law suites, she-sheds, backyard cottages—accessory dwelling units
                can go by many names. At their core, they're smaller, secondary homes built on the
                same lot as an existing residential property, often in the backyard. They can be
                detached units, connected to the primary home, or even converted spaces within the
                main house itself, such as a basement.
              </CommonParagraph>
              <CommonParagraph>
                ADUs are fully functional, independent living spaces complete with kitchens and
                bathrooms. When it comes to{" "}
                <BlogLink
                  url="https://selbysellssd.com/blog/maximize-space-san-diego-homes"
                  text="maximizing space"
                />{" "}
                and increasing the versatility of your property, ADUs can be a game-changer.
              </CommonParagraph>
              <Heading type="h2">The Benefits of ADUs</Heading>
              <Heading type="h3">Sellers</Heading>
              <CommonParagraph>
                In an age where space is limited, home prices are rising, and the cost of living
                keeps increasing, it's easy to see why homes with ADUs are attractive. If you're
                looking to{" "}
                <BlogLink
                  url="https://selbysellssd.com/sell-your-san-diego-home/"
                  text="sell your property"
                />
                that features an ADU, you'll likely attract many buyers.
              </CommonParagraph>
              <CommonParagraph>
                With more people interested in your property, there's the potential to create a
                bidding war and secure top-dollar for your home. Looking to get your home ready for
                the market? Put our{" "}
                <BlogLink
                  url="https://selbysellssd.com/real-estate-marketing-services"
                  text="Compass Concierge services"
                />
                to use. We'll ensure your home, including your ADU, stands out.
              </CommonParagraph>
              <Heading type="h3">Buyers</Heading>
              <CommonParagraph>
                ADUs are a great option for multi-generational families who want to live together
                while still enjoying their own space and privacy. They can also be used as a home
                office, guest suite, or even short-term rental space. That versatility and
                flexibility appeal to many modern{" "}
                <BlogLink
                  url="https://selbysellssd.com/buy-house-san-diego/"
                  text="buyers hitting the market"
                />
                .
              </CommonParagraph>
              <Heading type="h2">San Diego ADU Regulations</Heading>
              <Heading type="h3">Size</Heading>
              <CommonParagraph>
                San Diego ADU rules have changed when it comes to dimensions. While exact
                measurements may vary depending on your zoning laws, in general, ADUs cannot exceed
                1,200 square feet but must be at least 150 square feet. If an ADU is attached to the
                primary residence, it can't be more than 50% of the size of the home.
              </CommonParagraph>
              <Heading type="h3">Location</Heading>
              <CommonParagraph>
                ADUs are allowed in most San Diego residential zones, including multi-family and
                single-family ones. However, properties must meet specific lot requirements, and
                those requirements can vary zone to zone.
              </CommonParagraph>
              <Heading type="h3">Number of ADUs Allowed</Heading>
              <CommonParagraph>
                If you have a single-family property, you're typically allowed one ADU and a junior
                accessory dwelling unit (JADU). Multi-family properties can have more than one ADU;
                the exact number depends on zoning and space requirements.
              </CommonParagraph>
              <Heading type="h3">Multiple-Dwelling Unit Zones</Heading>
              <CommonParagraph>
                Do you live in a multi-dwelling unit zone? Then you may be able to add multiple
                ADUs, further boosting your property value or potential for rental income.
              </CommonParagraph>
              <Heading type="h3">Setback Regulations</Heading>
              <CommonParagraph>
                Generally speaking, ADUs must be at least four feet from side or rear property
                lines. However, it's important to research your zone and type of ADU, as converted
                spaces, such as garages, may have more flexible setback rules.
              </CommonParagraph>
              <Heading type="h3">Parking Requirements</Heading>
              <CommonParagraph>
                A big win for homeowners when it comes to new San Diego ADU regulations is the
                changed parking requirements. Now, ADUs located within half a mile of public
                transportation stops or in designated historic zones are exempt from requiring
                additional parking.
              </CommonParagraph>
              <Heading type="h2">Other ADUs Laws to Take Note Of</Heading>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px] flex flex-col gap-y-[10px]">
                <li>
                  In order to qualify as independent living units (such as for rental purposes),
                  ADUs must include basic amenities, such as a kitchen and bathroom.
                </li>
                <li>
                  While an ADU can be rented, it cannot be sold separately from the main residence.
                </li>
                <li>
                  There are specific building codes, permits, and regulations to comply with, and
                  they can vary from region to region. Before finalizing your San Diego ADU plans,
                  ensure you comply with local and state laws.
                </li>
              </ul>
              <Heading type="h2">Make the Most of ADUs</Heading>
              <CommonParagraph>
                Whether you're thinking about purchasing property with an accessory dwelling unit or
                adding one to an existing home you plan on selling down the road, The Selby Team can
                help you achieve your real estate goals. Learn more about our personalized, hands-on
                approach and how we can help you make the most of your home.{" "}
                <ContactSlideoutLink text="Let's get started!" />
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
